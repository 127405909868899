<template>
  <div>
    <v-btn
      min-width="0"
      text
      @click="show = true"
    >
      <v-badge
        :color="!!defectCount ? 'red' : null"
        :bordered="!!defectCount && !!defectCount > 0"
        overlap
      >
        <template
          v-if="!!defectCount && !!defectCount > 0"
          v-slot:badge
        >
          <span class="badge-count">{{ defectCount }}</span>
        </template>

        <v-icon
          size="22"
          color="white"
        >
          mdi-progress-wrench
        </v-icon>
      </v-badge>
    </v-btn>

    <defects-modal
      v-model="show"
      @close="show = false"
    />
  </div>
</template>

<script>
  import WebSocketTopic from '@api/web-socket-topic';
  import webSocketClient from '@mixin/web-socket-client';

  export default {
    components: {
      DefectsModal: () => import('@/views/admin/components/core/DefectsModal'),
    },

    mixins: [webSocketClient],

    apollo: {
      defectCount: {
        query: require('@gql/views/admin/components/core/defects/searchDefects.gql'),
        client: 'stations',
        fetchPolicy: 'no-cache',
        update: function (data) {
          return data.searchDefects.totalCount;
        },
      },
    },

    data: () => ({
      show: false,
    }),

    mounted () {
      this.webSocketSubscribe(WebSocketTopic.DEFECT_CREATED, this.onDefectCreated);
      this.webSocketSubscribe(WebSocketTopic.DEFECT_UPDATED, this.onDefectUpdated);
    },

    methods: {
      onDefectCreated (defect) {
        this.refresh();
      },
      onDefectUpdated (defect) {
        this.refresh();
      },
      refresh () {
        this.$apollo.queries.defectCount.refresh();
      },
    },
  };
</script>
